import React from "react"
import { useForm } from "react-hook-form"

import { graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PortableText from "@sanity/block-content-to-react"

const IndexPage = ({ data }) => {
  // cms
  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }
  const cmsbody = data.allSanityPage.nodes[0]._rawBody

  return (
    <Layout>
      <SEO title="Home" />

      <section className="ego">
        <PortableText className="max-w-prose-xs" blocks={cmsbody} serializers={serializers} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    allSanityPage(filter: { slug: { current: { eq: "index" } } }) {
      nodes {
        _rawBody
      }
    }
  }
`

export default IndexPage
